<template lang="pug">
.ds-pane
  .ds-pane-title
    slot(name="title")
      sidebar-title
        span {{ $t(title) }}
  .ds-pane-content.px-4
    slot
    slot(name="advanced")
      accordion
        template(#title)
          .font-weight-bold {{ $t('sidebarSettings.tabs.name.advanced') }}
        slot(name="advancedContent")
        slot(name="advancedContentEnding")
          slot(name="zIndex")
            pane-layout(layout="col")
              template(#left)
                .ds-label Z-index
              template(#right)
                om-input#zIndex(small v-model="zIndex")
          slot(name="savedStyle" v-if="$slots.savedStyle || savedStyle")
            custom-theme-style(dsAllowed)
          slot(name="customClass")
            om-input#customClass(
              :label="$t('customClass')"
              :helpText="$t('addCustomClassText')"
              small
              v-model="customClass"
            )
          slot(name="elementId")
            pane-layout(gap="4px")
              template(#left)
                .ds-label {{ $t('elementId') }}
              template(#right)
                .d-flex.align-items-center
                  om-input#elementId.w-100(small :value="elementId" readOnly)
                  om-button.p-0.ml-1.copyElement(
                    iconOnly
                    icon="copy-alt"
                    v-clipboard:copy="elementId"
                    v-clipboard:success="onCopy"
                  )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import CustomThemeStyle from '../../components/CustomThemeStyle.vue';

  export default {
    components: {
      Accordion,
      PaneLayout,
      CustomThemeStyle,
    },
    mixins: [itemMixin],
    props: {
      savedStyle: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapState(['selectedElement']),
      title() {
        return this.selectedElement.type.replace('Om', 'edit');
      },
      elementId() {
        return this.selectedElement.uid;
      },
      zIndex: {
        get() {
          return this.getValueOf('zIndex');
        },
        set(v) {
          this.setValueOf('zIndex', v);
        },
      },
      customClass: {
        get() {
          return this.getValueOf('data.customClasses');
        },
        set(v) {
          this.setValueOf('data.customClasses', v);
        },
      },
    },
    methods: {
      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.elementIdCopied'),
        });
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .ds-pane
    height: 100%
    &-content
      height: 100%
      overflow-y: scroll
      padding-bottom: 50px
      color: #23262A
    .ds-tooltip
      .popper
        max-width: 250px
    .ds-label
      color: $om-gray-700
    label
      &.input-label[for="customClass"],
      &.select-label[for="savedStyle"]
        padding: 0
    hr
      margin-block: 5px
</style>
