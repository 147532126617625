const EXPAND_OPENED = 'expand--opened';
const EXPAND_CLOSED = 'expand--closed';

const openLogic = (element) => {
  element.classList.add(EXPAND_OPENED);
  element.classList.remove(EXPAND_CLOSED);

  element.style.overflow = '';
  element.style.opacity = 1;
  element.style.height = 'fit-content';
};

const closeLogic = (element) => {
  element.classList.add(EXPAND_CLOSED);
  element.classList.remove(EXPAND_OPENED);

  element.style.overflow = 'hidden';
  element.style.opacity = 0;
  element.style.height = 0;
};

const logix = {
  open: openLogic,
  close: closeLogic,
};

export default {
  data: () => ({
    expandRef: 'expandable',
    expandState: 'expanded',
    expandWatcher: null,
    expanded: false,
  }),

  props: {
    fix: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    open: {
      handler(v) {
        this.expanded = v;
      },
      immediate: true,
    },
    expandRef: {
      handler(v) {
        if (v) {
          this._expandWatch();
        } else {
          this._expandUnwatch();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.fix) return;

    this._hideExpand();
    this._expandWatch();
  },
  methods: {
    _hideExpand() {
      if (!this.expandRef) return;
      const logic = this[this.expandState] ? 'open' : 'close';
      const ref = this.$refs[this.expandRef];
      if (Array.isArray(ref)) {
        Array.from(ref).forEach((el) => el && logix[logic](el));
      } else {
        ref && logix[logic](ref);
      }
    },
    _expandWatch() {
      this._expandWatcher = this.$watch(
        function () {
          return this[this.expandState];
        },
        (value) => {
          const logic = value ? 'open' : 'close';
          const ref = this.$refs[this.expandRef];
          if (Array.isArray(ref)) {
            Array.from(ref).forEach((el) => el && logix[logic](el));
          } else {
            ref && logix[logic](ref);
          }
        },
      );
    },
    _expandUnwatch() {
      if (this._expandWatcher) this._expandWatcher();
    },
  },
};
