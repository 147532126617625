<template lang="pug">
div
  .editor-heading.d-flex(@click="$emit('click')")
    .editor-heading-title.d-flex.align-items-center(:class="{ label }")
      slot
      .editor-heading-title-trailing(v-if="$slots.titleTrailing")
        slot(name="titleTrailing")
    .editor-heading-end(v-if="icon || $slots.prependEnding")
      slot(name="prependEnding")
      slot(name="toggler")
        component(v-if="icon" :is="iconComponent" :size="iconSize")
  .editor-heading-content(v-if="$slots.expandableContent")
    .editor-heading-content-inner(ref="expandable" :class="expandableClasses")
      slot(name="expandableContent")
  .editor-heading-help(v-if="$slots.help")
    .editor-heading-help-inner
      slot(name="help")
</template>

<script>
  import { UilAngleDown, UilAngleUp } from '@iconscout/vue-unicons';
  import iconComponentMixin from '@/components/Elements/mixins/iconComponent';
  import expandMixin from '@/components/Editor/mixins/expand';
  import { OmHeading } from '@/components/Elements';

  export default {
    name: 'EditorHeading',
    components: { OmHeading, UilAngleDown, UilAngleUp },
    mixins: [iconComponentMixin, expandMixin],
    props: {
      icon: {
        type: String,
        default: null,
        validator(value) {
          return ['angle-down', 'angle-up'].includes(value);
        },
      },
      label: {
        type: Boolean,
        default: false,
      },
      expandableGap: {
        type: String,
      },
    },
    computed: {
      expandableClasses() {
        if (!this.expandableGap) return;

        return 'd-flex flex-column gap-12';
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .editor-heading
    min-height: 2rem
    display: flex
    align-items: center
    justify-content: space-between
    &-title
      font-size: .875rem
      font-weight: bold
      &-trailing
        display: flex
      &.label
        color: $om-gray-700
        font-size: 0.75rem
        line-height: 1.5rem
        font-weight: 400
    &-end
      display: flex
      align-items: center
    &-content
      font-size: .75rem
      &-inner
        transition: opacity 150ms ease-out
    &-help
      font-size: 0.75rem
      color: #8F97A4
  .gap-12
    gap: 0.75rem
</style>
